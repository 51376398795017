/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalProdutosPesquisa from '../ModalProdutosPesquisa';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';

const { SearchBar } = Search;

export default function Pesquisas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const columns = [
    {
      dataField: 'revenda',
      text: 'Dental',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'faturamento',
      text: 'Faturamento Médio R$',
      formatter: (celula, valor) => {
        return Number(valor.faturamento)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdeProdutosVendidos',
      text: 'Quantidade de Produtos Vendidos',
      formatter: (celula, valor) => {
        return Number(valor.qtdeProdutosVendidos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'mediaMensal',
      text: 'Média Mensal',
      formatter: (celula, valor) => {
        return Number(valor.mediaMensal).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'share',
      text: 'Share',
      formatter: (celula, valor) => {
        return `${Number(valor.share).toLocaleString('pt-BR')} %`;
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdeVendedores',
      text: 'Quantidade de Vendedores',
      formatter: (celula, valor) => {
        return Number(valor.qtdeVendedores).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },

    // {
    //   dataField: 'tamanhoLoja',
    //   text: 'Tamanho da Loja m²',
    //   formatter: (celula, valor) => {
    //     return Number(valor.tamanhoLoja).toLocaleString('pt-BR');
    //   },
    //   sort: true,
    //   sortFunc: sortFunc,
    // },

    {
      dataField: 'produtosMaisVendidos',
      text: 'Produtos mais vendidos',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModal(
                valor.produtosMaisVendidos,
                valor.produtosMaisVendidosConcorrentes
              );
            }}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    { dataField: 'fonte', text: 'Fonte', sort: true },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.revenda);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'revenda',
      order: 'asc',
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarPesquisas();
  }, []);

  const buscarPesquisas = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/pesquisa_mercado?rede=${redeSelecionada}`
    );
    if (resultado) {
      setData(resultado.data.pesquisas);
    }
    setBuscando(false);
  };
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [concorrente, setConcorrente] = useState([]);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [revenda, setRevenda] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);

  const visualizarModal = (produtos, concorrente) => {
    setProdutos(JSON.parse(produtos));
    setConcorrente(JSON.parse(concorrente));
    setMostrarModal(true);
  };

  const fecharModal = () => {
    setMostrarModal(false);
  };

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const abrirModalExcluir = (id, revenda) => {
    setRevenda(revenda);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setRevenda('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarPesquisa = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pesquisas de Mercado</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarPesquisas}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalProdutosPesquisa
        show={mostrarModal}
        onHide={fecharModal}
        produtos={produtos}
        concorrente={concorrente}
      />
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        revenda={revenda}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarPesquisa={atualizarPesquisa}
      />
    </Container>
  );
}
