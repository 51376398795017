import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Container, Row } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

const { SearchBar } = Search;

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function ConfiguracaoTaxaSucesso() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      formatter: (celula, valor) => {
        return `${valor.rede}${valor.codProduto}`;
      },
    },
    {
      dataField: 'nomeRede',
      text: 'Rede',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'nomeProduto',
      text: 'Produto',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'mes',
      text: 'Mês Ref.',
      sort: true,
      formatter: (celula, valor) => {
        return valor.mes + '/' + valor.ano;
      },
      footer: (columnData) => null,
    },
    {
      dataField: 'valorUnitario',
      text: 'Valor Unitário',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorUnitario)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'qtdVendidaAntes',
      text: 'Qtd. Vendida Antes',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdVendidaAntes).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
  ];

  const [data, setData] = useState({
    configuracoesTaxaSucesso: [],
    count: 0,
    perPage: 10,
    page: 1,
  });
  const [buscando, setBuscando] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [redes, setRedes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());

  async function fetchData(idRede, idProduto, mes, ano) {
    setLoading(true);

    const queryParams = new URLSearchParams();
    queryParams.append('page', page);
    queryParams.append('perPage', perPage);
    if (idRede && idRede !== 'todos') queryParams.append('idRede', idRede);
    if (idProduto && idProduto !== 'todos')
      queryParams.append('idProduto', idProduto);
    if (mes && mes !== 'todos') queryParams.append('mes', mes);
    if (ano && ano !== 'todos') queryParams.append('ano', ano);

    const response = await api.get(
      `/api/configuracao-taxa-sucesso?${queryParams.toString()}`
    );

    if (response && response.data) setData(response.data);
    setLoading(false);
  }

  async function fetchRedes() {
    const response = await api.get('/api/redes');

    if (response && response.data && response.data.redes)
      setRedes(response.data.redes);
  }

  async function fetchProdutos() {
    const response = await api.get('/api/produtos');

    if (response && response.data && response.data.produtos)
      setProdutos(response.data.produtos);
  }

  useEffect(() => {
    fetchRedes();
    fetchProdutos();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    page,
    sizePerPage: perPage,
    totalSize: data?.count,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.count,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'pagination':
        perPage !== newState.sizePerPage && setPerPage(newState.sizePerPage);
        page !== newState.page && setPage(newState.page);
        break;
      default:
        break;
    }
  };

  function handleSearch() {
    const idRede = document.getElementById('idRede')?.value;
    const idProduto = document.getElementById('idProduto')?.value;
    const mes = document.getElementById('mes')?.value;
    const ano = document.getElementById('ano')?.value;

    fetchData(idRede, idProduto, mes, ano);
  }

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Configuração Taxa de Sucesso</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row
        style={{ display: 'flex', gap: 8, width: '100%', padding: '0 16px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="idRede">Redes</label>
          <select className="input-select mb-3" name="idRede" id="idRede">
            <option selected value="todos">
              Todos
            </option>
            {redes.map((rede) => (
              <option key={rede.id} value={rede.id}>
                {rede.nome}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="idProduto">Produtos</label>
          <select className="input-select mb-3" name="idProduto" id="idProduto">
            <option selected value="todos">
              Todos
            </option>
            {produtos.map((produto) => (
              <option key={produto.id} value={produto.id}>
                {produto.nome}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="ano">Ano</label>
          <select
            className="input-select mb-3"
            name="ano"
            id="ano"
            value={selectedYear}
            onChange={(e) => {
              console.log('onChange: ', typeof e.target.value);
              setSelectedYear(Number(e.target.value));
            }}
          >
            <option selected value="todos">
              Todos
            </option>
            {(() => {
              let anosSelect = [];
              for (let l = 2020; l <= moment().year(); l++) {
                anosSelect.push(<option value={l}>{l}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="mes">Mês</label>
          <select
            className="input-select mb-3"
            name="mes"
            id="mes"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option selected value="todos">
              Todos
            </option>
            {(() => {
              let mesesSelect = [];
              if (selectedYear === 2020) {
                for (var l = 7; l <= 12; l++) {
                  mesesSelect.push(<option value={l}>{meses[l - 1]}</option>);
                }
              } else if (selectedYear === moment().year()) {
                for (var j = 1; j <= moment().month(); j++) {
                  mesesSelect.push(<option value={j}>{meses[j - 1]}</option>);
                }
              } else {
                for (var j = 1; j <= 12; j++) {
                  mesesSelect.push(<option value={j}>{meses[j - 1]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
        </div>
        <button className="btn-busca" type="submit" onClick={handleSearch}>
          {buscando ? (
            <i className="fas fa-circle-notch fa-spin"></i>
          ) : (
            <i className="fas fa-search"></i>
          )}
        </button>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          remote
          keyField="id"
          data={data?.configuracoesTaxaSucesso}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                remote
                loading={loading}
                onTableChange={onTableChange}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
