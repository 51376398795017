import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';

export default function Home() {
  useEffect(() => {
    document.title =
      'Kulzer - Mais Vantagens';
  }, []);

  return (
    <>
      <Header auto />
      <Container className="d-none d-md-flex flex-column justify-content-center align-items-center">
        <Row>
          <Link to="/login">
            <img
              className="w-100 pt-5 pb-5"
              src="imagens/banner.png"
              alt="Kulzer"
            ></img>
          </Link>
        </Row>
      </Container>
      <Container className=" d-md-none ">
        <Row>
          <Link to="/login">
            <img
              className="w-100 mt-3 mb-3"
              src="imagens/banner-mobile.png"
              alt="Kulzer"
            ></img>
          </Link>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
