import React, { useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function AtualizarRelatorio() {
  const [atualizandoRelatorio, setAtualizandoRelatorio] = useState(false);
  const [atualizandoMensuracao, setAtualizandoMensuracao] = useState(false);
  const [atualizandoTaxaSucesso, setAtualizandoTaxaSucesso] = useState(false);
  const [atualizandoAntifraude, setAtualizandoAntifraude] = useState(false);
  const [atualizandoComprasPontuacao, setAtualizandoComprasPontuacao] =
    useState(false);
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');
  const [problemasTaxaSucesso, setProblemasTaxaSucesso] = useState([]);

  const atualizarRelatorio = async (e) => {
    e.preventDefault();
    setAtualizandoRelatorio(true);
    const resultado = await api.get('/api/relatorio/gerar');

    if (resultado) {
      toast.success(
        `Relatório período ${resultado.data.periodo} atualizado com sucesso`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
    }
    setAtualizandoRelatorio(false);
  };

  const atualizarComprasPontuacao = async (e) => {
    e.preventDefault();
    setAtualizandoComprasPontuacao(true);
    const resultado = await api.get(
      `/api/compras-pontuacao/gerar?ano=${ano}&mes=${mes}`
    );

    if (resultado) {
      toast.success(
        `Relatório de Compras x Pontuação período ${resultado.data.periodo} atualizado com sucesso`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
    }
    setAtualizandoComprasPontuacao(false);
  };

  const atualizarTaxaSucesso = async (e) => {
    e.preventDefault();
    setAtualizandoTaxaSucesso(true);
    try {
      const resultado = await api.get(
        `/api/taxa-sucesso/gerar?ano=${ano}&mes=${mes}`
      );

      if (resultado.data.problemas?.length > 0) {
        setProblemasTaxaSucesso(resultado.data.problemas);
        toast.warning(
          `Existem ${resultado.data.problemas.length} configurações faltantes. Verifique a tabela abaixo.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
      } else {
        toast.success(
          `Relatório Taxa de Sucesso período ${resultado.data.periodo} atualizado com sucesso`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
      }
    } catch (error) {
      toast.error('Erro ao gerar relatório de Taxa de Sucesso');
    } finally {
      setAtualizandoTaxaSucesso(false);
    }
  };

  const atualizarAntifraude = async (e) => {
    e.preventDefault();
    setAtualizandoAntifraude(true);
    const resultado = await api.get(`/api/antifraude/gerar`);

    if (resultado) {
      toast.success(`Relatório Antifraude atualizado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setAtualizandoAntifraude(false);
  };

  const atualizarMensuracao = async (e) => {
    e.preventDefault();
    setAtualizandoMensuracao(true);
    const resultado = await api.get(`/api/mensuracao/gerar`);

    if (resultado) {
      toast.success(`Relatório de Mensuração atualizado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setAtualizandoMensuracao(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Atualizar Relatórios</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <Row>
            <Col xs={6}>
              <label htmlFor="ano">Ano</label>
              <input
                id="ano"
                name="ano"
                type="number"
                placeholder="Ano"
                className="w-100"
                value={ano}
                onChange={(e) => setAno(e.target.value)}
              ></input>
            </Col>
            <Col xs={6}>
              <label htmlFor="mes">Mês</label>
              <input
                id="mes"
                name="mes"
                type="number"
                placeholder="Mês"
                value={mes}
                className="w-100"
                onChange={(e) => setMes(Number(e.target.value))}
              ></input>
            </Col>
          </Row>
          {atualizandoRelatorio ? (
            <button disabled className="btn-primario mb-3" type="button">
              ATUALIZANDO...
            </button>
          ) : (
            <button
              className="btn-primario mb-3"
              type="button"
              onClick={atualizarRelatorio}
            >
              ATUALIZAR RELATÓRIO
            </button>
          )}
          {atualizandoComprasPontuacao ? (
            <button disabled className="btn-primario mb-3" type="button">
              ATUALIZANDO...
            </button>
          ) : (
            <button
              className="btn-primario mb-3"
              type="button"
              onClick={atualizarComprasPontuacao}
            >
              ATUALIZAR COMPRAS X PONTUAÇÃO
            </button>
          )}
          {atualizandoTaxaSucesso ? (
            <button disabled className="btn-primario mb-3" type="button">
              ATUALIZANDO...
            </button>
          ) : (
            <button
              className="btn-primario mb-3"
              type="button"
              onClick={atualizarTaxaSucesso}
            >
              ATUALIZAR TAXA DE SUCESSO
            </button>
          )}
          {atualizandoAntifraude ? (
            <button disabled className="btn-primario mb-3" type="button">
              ATUALIZANDO...
            </button>
          ) : (
            <button
              className="btn-primario mb-3"
              type="button"
              onClick={atualizarAntifraude}
            >
              ATUALIZAR ANTIFRAUDE
            </button>
          )}
          {atualizandoMensuracao ? (
            <button disabled className="btn-primario mb-3" type="button">
              ATUALIZANDO...
            </button>
          ) : (
            <button
              className="btn-primario mb-3"
              type="button"
              onClick={atualizarMensuracao}
            >
              ATUALIZAR MENSURAÇÃO
            </button>
          )}
        </form>
      </div>
      {problemasTaxaSucesso.length > 0 && (
        <div className="mt-4">
          <h5>Configurações faltantes:</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Rede</th>
                <th>Produto</th>
                <th>Período</th>
                <th>Problema</th>
              </tr>
            </thead>
            <tbody>
              {problemasTaxaSucesso.map((problema, index) => (
                <tr key={index}>
                  <td>{problema.rede}</td>
                  <td>{problema.produto}</td>
                  <td>{problema.periodo}</td>
                  <td>{problema.mensagem}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
}
