import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Input } from '@rocketseat/unform';

import moment from 'moment-timezone';
import api from '../../../services/API';
import { toast } from 'react-toastify';

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function CadastrarConfiguracaoTaxaSucesso() {
  const [enviando, setEnviando] = useState(false);
  const [redes, setRedes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());

  async function fetchRedes() {
    const response = await api.get('/api/redes');

    if (response && response.data && response.data.redes)
      setRedes(response.data.redes);
  }

  async function fetchProdutos() {
    const response = await api.get('/api/produtos');

    if (response && response.data && response.data.produtos)
      setProdutos(response.data.produtos);
  }

  useEffect(() => {
    fetchRedes();
    fetchProdutos();
  }, []);

  function getFormData() {
    return {
      idRede: document.getElementById('idRede').value,
      idProduto: document.getElementById('idProduto').value,
      valorUnitario: document.getElementById('valorUnitario').value,
      qtdVendidaAntes: document.getElementById('qtdVendidaAntes').value,
      ano: selectedYear,
      mes: selectedMonth,
    };
  }

  function resetForm() {
    document.getElementById('idRede').value = '';
    document.getElementById('idProduto').value = '';
    document.getElementById('valorUnitario').value = '';
    document.getElementById('qtdVendidaAntes').value = '';
    setSelectedYear(moment().year());
    setSelectedMonth(moment().month());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const body = getFormData();
    const response = await api.post('/api/configuracao-taxa-sucesso', body);

    if (response && response.data) {
      toast.success('Configuração criada com sucesso.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    }
  }

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">
          Cadastrar Configuração Taxa de Sucesso
        </h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={handleSubmit}>
          <label htmlFor="idRede" />
          <select name="idRede" id="idRede" required>
            <option selected value="" disabled>
              Selecione a rede
            </option>
            {redes.map((rede) => (
              <option key={rede.id} value={rede.id}>
                {rede.nome}
              </option>
            ))}
          </select>
          <label htmlFor="idProduto" />
          <select name="idProduto" id="idProduto" required>
            <option selected value="" disabled>
              Selecione o produto
            </option>
            {produtos.map((produto) => (
              <option key={produto.id} value={produto.id}>
                {produto.nome}
              </option>
            ))}
          </select>
          <label htmlFor="valorUnitario">Valor Unitário</label>
          <Input
            id="valorUnitario"
            name="valorUnitario"
            type="number"
            step="0.01"
            placeholder="Preço Unitário"
          ></Input>
          <label htmlFor="qtdVendidaAntes">Quantidade Vendida Antes</label>
          <input
            id="qtdVendidaAntes"
            name="qtdVendidaAntes"
            type="number"
            placeholder="Quantidade Vendida Antes"
          ></input>
          <label htmlFor="ano">Ano</label>
          <select
            required
            name="ano"
            id="ano"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {(() => {
              let anosSelect = [];
              for (let l = 2020; l <= moment().year(); l++) {
                anosSelect.push(<option value={l}>{l}</option>);
              }
              return anosSelect;
            })()}
          </select>
          <label htmlFor="mes">Mês</label>
          <select
            name="mes"
            id="mes"
            onChange={(e) => setSelectedMonth(e.target.value)}
            defaultValue={selectedMonth}
          >
            {(() => {
              let mesesSelect = [];
              if (selectedYear === 2020) {
                for (var l = 7; l <= moment().month(); l++) {
                  mesesSelect.push(<option value={l}>{meses[l - 1]}</option>);
                }
              } else {
                for (var j = 1; j <= moment().month(); j++) {
                  mesesSelect.push(<option value={j}>{meses[j - 1]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
