import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function UploadVendas() {
  const [enviando, setEnviando] = useState(false);
  const [arquivo, setArquivo] = useState(false);
  const [status, setStatus] = useState('Procurar...');
  const [enviarMensagem, setEnviarMensagem] = useState(true);

  const uploadProduto = async (e) => {
    e.preventDefault();

    if (!arquivo) {
      toast.error(`Selecione um arquivo primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();
    formData.append('arquivo', arquivo);
    formData.append('enviarMensagem', enviarMensagem);
    setEnviando(true);
    const resultado = await api.post('/api/upload/vendas', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success(
        `${Number(resultado.data.total).toLocaleString(
          'pt-BR'
        )} venda(s) processada(s)`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      setArquivo(null);
      setStatus('Procurar...');
    }
    setEnviando(false);
  };

  useEffect(() => {
    if (!arquivo) {
      document.getElementById('arquivo').value = null;
    }
  }, [arquivo]);

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Upload Vendas Dental</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={uploadProduto}>
          <label htmlFor="arquivo">Arquivo .xlsx</label>
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files[0]);
              e.target.files[0]
                ? setStatus(e.target.files[0].name)
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />
          <div className="d-flex justify-content-center align-items-center w-100 mb-3">
            <input
              type="checkbox"
              name="check"
              id="enviarMensagem"
              className="m-1"
              onChange={(e) => setEnviarMensagem(e.target.checked)}
              checked={enviarMensagem}
            />
            <label htmlFor="checkRegulamento" className="m-0 f-black f-14">
              Enviar mensagem via WhatsApp
            </label>
          </div>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              ENVIANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              ENVIAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
