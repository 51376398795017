import React from 'react';
import { Container } from 'react-bootstrap';
import historico from '../../../services/Historico';

export default function MenuCadastrarProduto() {
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Produto</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <button
            className="btn-primario"
            type="submit"
            name="padrao"
            onClick={(e) => {
              e.preventDefault();
              historico.push('/painel/cadastrar/produto/criteria');
            }}
          >
            Programa de Incentivo - Kulzer
          </button>
          <button
            className="btn-primario"
            type="submit"
            name="revenda"
            onClick={(e) => {
              e.preventDefault();
              historico.push('/painel/cadastrar/produto/revenda');
            }}
          >
            REVENDA
          </button>
        </form>
      </div>
    </Container>
  );
}
