import React from 'react';
import AsyncSelect from 'react-select/async';
import { useState } from 'react';
import api from '../../../services/API';
import './styles.css';

export default function SelectRevenda(props, ref) {
  const [revendas, setRevendas] = useState([]);

  const carregaPrimeiraVez = async () => {
    const resultado = await api.get('/api/revendas');

    const valores = [];

    resultado.data.revendas.forEach((revenda) =>
      valores.push({
        value: revenda.cnpj,
        label: `${revenda.cnpj} - ${revenda.nomeFantasia}`,
      })
    );

    setRevendas(valores);
    return valores;
  };

  const filtrarRevenda = async (valor) =>
    await revendas.filter((revenda) =>
      revenda.label.toLowerCase().includes(valor)
    );

  const carregarRevendas = (valor) =>
    revendas.length === 0 ? carregaPrimeiraVez() : filtrarRevenda(valor);

  return (
    <AsyncSelect
      noOptionsMessage={() => 'Sem resultados'}
      onChange={props.onChange}
      placeholder="Selecione uma Dental"
      className="react-select-container mb-3"
      classNamePrefix="react-select"
      cacheOptions
      defaultOptions
      loadOptions={carregarRevendas}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: 'black',
          primary25: '#aac000',
          primary: '#aac000',
        },
      })}
    />
  );
}
