import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState('');
  const [subCategoriaId, setSubCategoriaId] = useState('');
  const [showSubCategoria, setShowSubCategoria] = useState(false);

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const buscarCategorias = async () => {
    try {
      const response = await api.get('/api/premios/categorias?level=1');
      if (response?.data?.categorias) {
        setCategorias(response.data.categorias);
      }
    } catch (error) {
      toast.error('Erro ao carregar categorias');
    }
  };

  const buscarSubCategorias = async (categoriaId) => {
    try {
      const response = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${categoriaId}`);
      console.log('Subcategorias carregadas:', response.data);
      if (response?.data?.categorias) {
        setSubCategorias(response.data.categorias);
        setShowSubCategoria(true);
      }
    } catch (error) {
      console.error('Erro ao buscar subcategorias:', error);
      setSubCategorias([]);
      setShowSubCategoria(false);
    }
  };

  const atualizar = async (e) => {
    e.preventDefault();
    
    if (!categoriaId) {
      toast.error('Selecione uma categoria', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEditando(true);

    let formData = new FormData();
    formData.append('nome', nome || data.nome);
    formData.append('descricao', descricao || data.descricao);
    formData.append('codigo', codigo || data.codigo);
    formData.append('custoTotal', custoTotal || data.custoTotal);
    formData.append('tipo', tipo || data.tipo);
    if (foto) {
      formData.append('foto', foto);
    }
    formData.append('codigoCategoria', (subCategoriaId || categoriaId).toString());

    try {
      const resultado = await api.put(`/api/premios/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resultado) {
        toast.success('Prêmio atualizado com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        limparEstados();
        props.onHide();
        props.atualizarPremio({
          ...{
            nome: nome || data.nome,
            descricao: descricao || data.descricao,
            codigo: codigo || data.codigo,
            custoTotal: custoTotal || data.custoTotal,
            tipo: tipo || data.tipo,
            codigoCategoria: subCategoriaId || categoriaId
          },
          id: data.id,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.erro || 'Erro ao atualizar prêmio');
    } finally {
      setEditando(false);
    }
  };

  useEffect(() => {
    const carregarDadosPremio = async () => {
      try {
        if (!props.data) {
          console.log('Nenhum dado recebido');
          return;
        }

        
        setData(props.data);
        setNome(props.data.nome || '');
        setDescricao(props.data.descricao || '');
        setCodigo(props.data.codigo || '');
        setCustoTotal(props.data.custoTotal || '');
        setTipo(props.data.tipo || 'geral');
        setPreview(props.data.diretorioFoto || null);
        
        const categoriasResponse = await api.get('/api/premios/categorias?level=1');
        console.log('Categorias carregadas:', categoriasResponse.data);
        
        if (categoriasResponse?.data?.categorias) {
          setCategorias(categoriasResponse.data.categorias);
        }

        if (props.data.codigoCategoria) {
          const categoriaResponse = await api.get(`/api/premios/categorias?idCategoria=${props.data.codigoCategoria}`);

          if (categoriaResponse?.data?.categorias?.length > 0) {
            const categoria = categoriaResponse.data.categorias[0];
            
            if (categoria.level === 2) {
              setCategoriaId(categoria.idCategoriaPai);
              
              const subCategoriasResponse = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${categoria.idCategoriaPai}`);
              if (subCategoriasResponse?.data?.categorias) {
                setSubCategorias(subCategoriasResponse.data.categorias);
                setShowSubCategoria(true);
                setSubCategoriaId(props.data.codigoCategoria);
              }
            } else {
              setCategoriaId(props.data.codigoCategoria);
              
              const subCategoriasResponse = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${props.data.codigoCategoria}`);
              if (subCategoriasResponse?.data?.categorias) {
                setSubCategorias(subCategoriasResponse.data.categorias);
                setShowSubCategoria(true);
              }
            }
          }
        }
      } catch (error) {
        toast.error('Erro ao carregar dados do prêmio');
      }
    };

    carregarDadosPremio();
  }, [props.data]);

  const limparEstados = () => {
    setData({});
    setDescricao('');
    setNome('');
    setCodigo('');
    setCustoTotal('');
    setTipo('');
    setFoto(null);
    setPreview(null);
    setStatus('Procurar...');
    setCategoriaId('');
    setSubCategoriaId('');
    setShowSubCategoria(false);
    setSubCategorias([]);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onExited={limparEstados}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form className="form" onSubmit={atualizar}>
            <label htmlFor="foto">Foto</label>
            <input
              type="file"
              id="foto"
              name="foto"
              style={{ display: 'none' }}
              onChange={alterarFoto}
            />
            <input
              className="text-left"
              id="botao"
              type="button"
              value={status}
              onClick={(e) => {
                document.getElementById('foto').click();
              }}
            />
            {preview && (
              <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
            )}
            <label htmlFor="nome">Código</label>
            <Input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            ></Input>
            <label htmlFor="nome">Nome</label>
            <Input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></Input>
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              placeholder="Descrição"
              className="premio-desc p-2"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            ></textarea>
            <label htmlFor="categoria">Categoria</label>
            <select
              id="categoria"
              name="categoria"
              value={categoriaId}
              onChange={(e) => {
                const id = e.target.value;
                setCategoriaId(id);
                setSubCategoriaId('');
                if (id) {
                  buscarSubCategorias(id);
                } else {
                  setShowSubCategoria(false);
                  setSubCategorias([]);
                }
              }}
              className="form-control"
            >
              <option value="">Selecione uma categoria</option>
              {categorias && categorias.length > 0 && categorias.map(categoria => (
                <option key={categoria.idCategoria} value={categoria.idCategoria}>
                  {categoria.nome}
                </option>
              ))}
            </select>

            {showSubCategoria && subCategorias && subCategorias.length > 0 && (
              <>
                <label htmlFor="subcategoria">Sub-categoria</label>
                <select
                  id="subcategoria"
                  name="subcategoria"
                  value={subCategoriaId}
                  onChange={(e) => setSubCategoriaId(e.target.value)}
                  className="form-control"
                >
                  <option value="">Selecione uma sub-categoria</option>
                  {subCategorias.map(subcategoria => (
                    <option key={subcategoria.idCategoria} value={subcategoria.idCategoria}>
                      {subcategoria.nome}
                    </option>
                  ))}
                </select>
              </>
            )}

            <label htmlFor="custoTotal">Custo Total</label>
            <Input
              id="custoTotal"
              name="custoTotal"
              type="number"
              step="0.01"
              placeholder="Custo Total"
              value={custoTotal}
              onChange={(e) => setCustoTotal(e.target.value)}
            ></Input>
            <label htmlFor="tipo">Tipo</label>
            <select
              id="tipo"
              name="tipo"
              placeholder="Tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option disabled value="">
                Selecione o tipo
              </option>
              <option value="geral">Geral</option>
              <option value="bivolt">Bivolt</option>
              <option value="credito">Crédito</option>
              <option value="recarga">Recarga</option>
            </select>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={() => {
              limparEstados();
              props.onHide();
            }}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
