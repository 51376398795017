/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { 
  SizePerPageDropdownStandalone,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalAprovarResgate from '../ModalAprovarResgate';
import ModalAlterarStatusResgate from '../ModalAlterarStatusResgate';
import ModalCancelarResgate from '../ModalCancelarResgate';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

import ModalMassRescueAlteration from './ModalMassRescueAlteration';
import ModalObsEdit from './ModalObsEdit';

const { SearchBar } = Search;

export default function Resgates() {
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [
    mostrarModalAlterarStatusResgate,
    setMostrarModalAlterarStatusResgate,
  ] = useState(false);
  const [mostrarModalAprovarResgate, setMostrarModalAprovarResgate] =
    useState(false);
  const [mostrarModalCancelarResgate, setMostrarModalCancelarResgate] =
    useState(false);
  const [resgate, setResgate] = useState('');
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showSelectionIdsModal, setShowSelectionIdsModal] = useState(false);
  const [showObsEditModal, setShowObsEditModal] = useState(false);
  const [obs, setObs] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: 0
  });
  const [searchText, setSearchText] = useState('');

  const formatarDataPrevisao = (celula, valor) => {
    if (
      valor.viaData?.entrega?.erro ||
      !valor.viaData?.entrega?.entregas[0]?.dataPrevisao
    )
      return '-';
    return moment(
      valor.viaData.entrega.entregas[0].dataPrevisao,
      'DD/MM/YYYY HH:mm'
    ).format('DD/MM/YYYY');
  };

  const formatarDataEntrega = (celula, valor) => {
    if (
      valor.viaData?.entrega?.erro ||
      !valor.viaData?.entrega?.entregas[0]?.dataEntrega
    )
      return '-';
    return moment(
      valor.viaData.entrega.entregas[0].dataEntrega,
      'DD/MM/YYYY HH:mm'
    ).format('DD/MM/YY');
  };

  const formatarData = (celula, valor) => {
    return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
  };

  const formatarQtde = (celula, valor) => {
    return Number(valor.qtde).toLocaleString('pt-BR');
  };

  const formatarSaldo = (celula, valor) => {
    return Number(valor.saldoAnterior).toLocaleString('pt-BR');
  };

  const formatarGasto = (celula, valor) => {
    return Number(valor.pontosGastos).toLocaleString('pt-BR');
  };

  const formatarStatus = (celula, valor) => {
    if (valor.status === 0) {
      return (
        <span>
          <i className="fa fa-times"></i> Não
        </span>
      );
    } else if (valor.status === 1) {
      return (
        <span>
          <i className="fa fa-check"></i> Entregue
        </span>
      );
    } else if (valor.status === 2) {
      return (
        <span>
          <i className="fa fa-truck"></i> Em transporte
        </span>
      );
    } else if (valor.status === 3) {
      return (
        <span>
          <i className="fa fa-times"></i> Cancelado
        </span>
      );
    } else if (valor.status === 4) {
      return (
        <span>
          <i className="fa fa-times"></i> Aguardando liberação
        </span>
      );
    } else if (valor.status === 5) {
      return (
        <span>
          <i className="fa fa-times"></i> Cancelado pelo Fornecedor
        </span>
      );
    } else if (valor.status === 6) {
      return (
        <span>
          <i className="fa fa-check"></i> Liberado
        </span>
      );
    }
  };

  const formatarPremioResgatado = (celula, valor) => {
    return valor.obs ? `${valor.premio} - ${valor.obs}` : `${valor.premio}`;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'parceiro',
      text: 'Vendedor Dental',
      sort: true,
    },
    {
      dataField: 'revenda',
      text: 'Dental',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'premio',
      text: 'Produto Resgatado',
      formatter: formatarPremioResgatado,
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      formatter: formatarQtde,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'obs',
      text: 'Observação',
    },
    {
      dataField: 'saldoAnterior',
      text: 'Saldo no Ato',
      formatter: formatarSaldo,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      formatter: formatarGasto,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'status',
      text: 'Entregue',
      formatter: formatarStatus,
      sort: true,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data do Resgate',
      formatter: formatarData,
      sort: true,
    },
    {
      dataField: 'valor.viaData.entrega.entregas[0].dataEntrega',
      text: 'Data de Entrega',
      formatter: formatarDataEntrega,
      sort: true,
    },
    {
      dataField: 'valor.viaData.entrega.entregas[0].dataPrevisao',
      text: 'Previsão de Entrega',
      formatter: formatarDataPrevisao,
      sort: true,
    },
    {
      text: 'Aprovar Resgate',
      dataField: 'aprovarResgate',
      isDummyField: true,
      formatter: (celula, valor) =>
        valor.status === 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalAprovarResgate(valor.id);
            }}
          >
            <i className="fas fa-check f-green"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-check"></i>
          </button>
        ),
    },
    {
      text: 'Alterar Observação',
      isDummyField: true,
      dataField: 'changeObs',
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setObs(valor.obs);
            setResgate(valor.id);
            setShowObsEditModal(true);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),

      sort: true,
    },
    {
      text: 'Cancelar Resgate',
      isDummyField: true,
      dataField: 'cancelarResgate',
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarResgate(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            CANCELADO
          </button>
        ),
    },
    {
      text: 'Alterar Status',
      dataField: 'alterarStatus',
      isDummyField: true,
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalAlterarStatusResgate(valor.id);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            <i className="fas fa-edit"></i>
          </button>
        ),
    },
    // {
    //   text: 'Baixar NF',
    //   isDummyField: true,
    //   dataField: 'baixarNF',
    //   formatter: (celula, valor) => (
    //     <button
    //       className="btn-limpo f-12 p-1"
    //       onClick={() => {
    //         visualizarModalCancelarResgate(valor.id);
    //       }}
    //     >
    //       <i className="fa fa-arrow-down"></i>
    //     </button>
    //   ),
    // },
  ];

  const defaultSorted = [
    {
      dataField: 'resgatadoEm',
      order: 'desc',
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarResgates();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === 'pagination') {
      const newSizePerPage = sizePerPage === 'Todos' ? 0 : Number(sizePerPage);
      
      setPagination(prev => ({
        ...prev,
        page: page,
        sizePerPage: newSizePerPage
      }));
      
      buscarResgates({
        newPage: page,
        newSizePerPage: newSizePerPage
      });
    } else if (type === 'sort') {
      buscarResgates({
        newPage: pagination.page,
        newSizePerPage: pagination.sizePerPage,
        sortField,
        sortOrder
      });
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    setPagination(prev => ({
      ...prev,
      page: 1
    }));
  };

  const handleRedeChange = (e) => {
    const novaRede = e.target.value;
    setRedeSelecionada(novaRede);
    setPagination(prev => ({
      ...prev,
      page: 1
    }));
  };

  const buscarResgates = async (paginationUpdate = null) => {
    setBuscando(true);
    try {
      const currentPage = paginationUpdate?.newPage || pagination.page;
      const currentSizePerPage = paginationUpdate?.newSizePerPage ?? pagination.sizePerPage;
      
      const params = {
        pagina: currentPage,
        limite: currentSizePerPage === 'Todos' || currentSizePerPage === 0 ? '0' : String(currentSizePerPage),
        busca: searchText
      };
      
      if (redeSelecionada && redeSelecionada !== '') {
        params.rede = redeSelecionada;
      }

      if (paginationUpdate?.sortField) {
        params.sortField = paginationUpdate.sortField;
        params.sortOrder = paginationUpdate.sortOrder;
      }

      const resultado = await api.get('/api/resgates', { params });
      
      if (resultado?.data?.resgates) {
        setData(resultado.data.resgates.rows || []);
        setPagination(prev => ({
          ...prev,
          page: currentPage,
          sizePerPage: currentSizePerPage,
          totalSize: resultado.data.resgates.count || 0
        }));
      }
    } catch (error) {
      console.error('Erro ao buscar resgates:', error);
      setData([]);
    } finally {
      setBuscando(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await buscarRedes();
      await buscarResgates();
    };
    fetchInitialData();
  }, []);

  const visualizarModalAlterarStatusResgate = (id) => {
    setResgate(id);
    setMostrarModalAlterarStatusResgate(true);
  };

  const fecharModalAlterarStatusResgate = () => {
    setMostrarModalAlterarStatusResgate(false);
  };

  const visualizarModalAprovarResgate = (id) => {
    setResgate(id);
    setMostrarModalAprovarResgate(true);
  };

  const fecharModalAprovarResgate = () => {
    setMostrarModalAprovarResgate(false);
  };

  const visualizarModalCancelarResgate = (id) => {
    setResgate(id);
    setMostrarModalCancelarResgate(true);
  };

  const fecharModalCancelarResgate = () => {
    setMostrarModalCancelarResgate(false);
  };

  const paginationOptions = {
    page: pagination.page,
    sizePerPage: pagination.sizePerPage === 0 ? 'Todos' : pagination.sizePerPage,
    totalSize: pagination.totalSize,
    paginationSize: 5,
    showTotal: true,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'Todos', value: 0 }
    ],
    onSizePerPageChange: (sizePerPage) => {
      setPagination(prev => ({
        ...prev,
        page: 1,
        sizePerPage: Number(sizePerPage)
      }));
      
      buscarResgates({
        newPage: 1,
        newSizePerPage: Number(sizePerPage)
      });
    },
    paginationTotalRenderer: (from, to, size) => {
      if (pagination.sizePerPage === 0) {
        return (
          <span className="react-bootstrap-table-pagination-total">
            {` Mostrando todos os ${size} resultados`}
          </span>
        );
      }
      
      const validFrom = isNaN(from) ? 1 : from;
      const validTo = isNaN(to) ? size : to;
      const validSize = isNaN(size) ? 0 : size;
      
      return (
        <span className="react-bootstrap-table-pagination-total">
          {` Mostrando ${validFrom} até ${validTo} de ${validSize} resultados`}
        </span>
      );
    }
  };

  const onHideSelectionIdsModal = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.ids.includes(rescue.id)) {
            rescue.status = +toUpdate.status;
          }
          return rescue;
        })
      );
    }

    setShowSelectionIdsModal(false);
  };

  const onHideModalObsEdit = (toUpdate) => {
    if (toUpdate) {
      setData((prev) =>
        prev.map((rescue) => {
          if (toUpdate.id === rescue.id) {
            rescue.obs = toUpdate.obs;
          }
          return rescue;
        })
      );
    }

    setObs('');
    setShowObsEditModal(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Resgates</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={handleRedeChange}
            value={redeSelecionada}
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
          <button
            onClick={() => setShowSelectionIdsModal(true)}
            className="btn-primario-slim px-4 d-flex g-4 align-items-center justify-content-center"
          >
            <i className="fas fa-stream mr-2"></i>
            <span>Alterar Status em Massa</span>
          </button>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarResgates}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'flex-end', marginBottom: '-1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    {...props.searchProps}
                    placeholder="Buscar"
                    className="input-theme"
                    style={{ 
                      height: '38px',
                      borderRadius: '4px',
                      padding: '0.375rem 0.75rem',
                      border: '1px solid #ced4da',
                      boxSizing: 'border-box',
                      width: '200px',
                      display: 'block',
                      marginRight: '0.5rem'
                    }}
                    value={searchText}
                    onChange={e => handleSearch(e.target.value)}
                  />
                  <button 
                    className="btn-busca"
                    type="submit" 
                    onClick={buscarResgates}
                    style={{ 
                      height: '38px',
                      padding: '0.375rem 0.75rem',
                      border: '1px solid #ced4da',
                      boxSizing: 'border-box',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '4px'
                    }}
                  >
                    {buscando ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </div>
              </div>
              <BootstrapTable
                remote={{ 
                  pagination: true,
                  sort: true,
                  filter: false 
                }}
                loading={buscando}
                onTableChange={handleTableChange}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(paginationOptions)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalCancelarResgate
        show={mostrarModalCancelarResgate}
        onHide={fecharModalCancelarResgate}
        resgate={resgate}
      />
      <ModalAprovarResgate
        show={mostrarModalAprovarResgate}
        onHide={fecharModalAprovarResgate}
        resgate={resgate}
      />
      <ModalAlterarStatusResgate
        show={mostrarModalAlterarStatusResgate}
        onHide={fecharModalAlterarStatusResgate}
        resgate={resgate}
      />
      <ModalMassRescueAlteration
        open={showSelectionIdsModal}
        onHide={onHideSelectionIdsModal}
        resgate={resgate}
      ></ModalMassRescueAlteration>
      <ModalObsEdit
        open={showObsEditModal}
        onHide={onHideModalObsEdit}
        resgate={resgate}
        obs={obs}
        setObs={setObs}
      ></ModalObsEdit>
    </Container>
  );
}
