import React, { useState } from 'react';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';
import api from '../../../services/API';
export default function PontosFaltantes() {
  const [data, setData] = useState(new Date().toISOString().split('T')[0]);
  const [loading, setLoading] = useState(false);
  const baixarCSV = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/pontuacoes/expirar/relatorio', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'expired-score.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success('Arquivo CSV baixado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } catch (error) {
      toast.error('Erro ao baixar arquivo CSV', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };
  const devolverPontos = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/api/pontuacoes/reativar/${data}`);
      console.log('Resposta da API:', response);

      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        throw new Error(response.data.message || 'Erro ao devolver pontos');
      }
    } catch (error) {
      console.error('Erro ao devolver pontos:', error);
      toast.error(error.message || 'Erro ao devolver pontos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };
  const processarPontosExpirados = async () => {
    setLoading(true);
    try {
      const response = await api.post('/api/pontuacoes/expirar');
      
      if (response.data && response.data.success) {
        toast.success('Pontos processados com sucesso!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        throw new Error(response.data.message || 'Erro ao processar pontos');
      }
    } catch (error) {
      console.error('Erro ao processar pontos:', error);
      toast.error('Erro ao processar pontos expirados', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Expirar e Devolver Pontos</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Card className="shadow-sm" style={{ maxWidth: '600px', width: '100%' }}>
          <Card.Body className="p-4">
            <h5 className="text-center mb-4">Gerenciamento de Pontos</h5>
            <div className="d-flex flex-column gap-3">
              <Button
                style={{ backgroundColor: '#aac000', borderColor: '#aac000' }}
                onClick={processarPontosExpirados}
                disabled={loading}
                className="w-100 py-2 mb-3"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processando...
                  </>
                ) : (
                  'Processar Pontos Expirados'
                )}
              </Button>
              <Button
                style={{ backgroundColor: '#aac000', borderColor: '#aac000' }}
                onClick={baixarCSV}
                disabled={loading}
                className="w-100 py-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Baixando...
                  </>
                ) : (
                  'Baixar CSV'
                )}
              </Button>
              <Form.Group>
                <Form.Label className="text-muted">Data de Reativação:</Form.Label>
                <Form.Control
                  type="date"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  disabled={loading}
                  className="mb-3"
                />
              </Form.Group>
              <Button
                style={{ backgroundColor: '#aac000', borderColor: '#aac000' }}
                onClick={devolverPontos}
                disabled={loading}
                className="w-100 py-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processando...
                  </>
                ) : (
                  'Devolver Pontos'
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={() => historico.push('/painel/processar')}
                disabled={loading}
                className="w-100 py-2 mt-3"
              >
                VOLTAR
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
} 