import React from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Slider() {
  return (
    <Container fluid="md" className="mb-5">
      <Row>
        <div className="d-none d-sm-block">
          <Carousel interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="pontos-produtos">
                  <img
                    className="d-block w-100"
                    src="imagens/01.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="pontos-produtos">
                  <img
                    className="d-block w-100"
                    src="imagens/01_mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="d-sm-none">
          <Carousel indicators={false} interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="pontos-produtos">
                  <img
                    className="d-block w-100"
                    src="imagens/01.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="pontos-produtos">
                  <img
                    className="d-block w-100"
                    src="imagens/01_mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
